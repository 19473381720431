<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Peminjaman Alat</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <borrowingTools-grid ref="grid" :key="gridReload" :editClick="editClick" :statusClick="statusClick" />
                <borrowingTools-create-form ref="borrowingToolsCreateForm" :reload="reload"/>
                <borrowingTools-status-form ref="BorrowingToolsStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import borrowingToolsGrid from '../BorrowingTools/Grid/BorrowingToolsGrid.vue';
import borrowingToolsCreateForm from '../BorrowingTools/Component/BorrowingToolsCreateForm.vue';
import borrowingToolsStatusForm from '../BorrowingTools/Component/BorrowingToolsStatusForm.vue';

export default {
    name: 'BorrowingTools',
    components: {
        'borrowingTools-grid': borrowingToolsGrid,
        'borrowingTools-create-form': borrowingToolsCreateForm,
        'borrowingTools-status-form': borrowingToolsStatusForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Peminjaman Alat');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.borrowingToolsCreateForm.addClick();
        },
        editClick(data, view){
            const routeData = this.$router.resolve({ name: 'Borrowing Tools Form', params: {  formtype:'Edit', id:data, view:view } });
            window.open(routeData.href, '_blank');
        },
        statusClick(id, status) {
            this.$refs.BorrowingToolsStatusForm.statusClick(id, status);
        },
        reload(){
            this.gridReload++;
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>